import './footer.css'
import { Link as ScrollLink } from 'react-scroll';
import arrowUp from './arrowTop.gif';
import { ReactComponent as IconEmail } from './icons/email.svg';
import { ReactComponent as IconGithub } from './icons/github.svg';
import { ReactComponent as IconLinkedin } from './icons/linkedin.svg';
import { ReactComponent as IconTel } from './icons/tel.svg';

import {
    Container, Row, Col, Button
} from 'reactstrap';

const Footer = () => {
    return (
        <Container className='footer-container pt-5' id='about'>
            <Row className='d-flex'>
                <Col xxs={8}  className='col-copyright'>
                    <p>
                        Copyright © 2024 Ebou Christelle.
                    </p>
                    <div className='contact'>
                        <a href='https://www.linkedin.com/in/christyb-a48bb71a4/'><IconLinkedin width={25} height={40}/></a>
                        <a href='https://github.com/christy-B?tab=repositories'><IconGithub width={25} height={40}/></a>
                        <a href='mailto:bienechristy94@gmail.com'><IconEmail width={25} height={40}/></a>
                        <a href='tel:+33662257759'><IconTel width={25} height={40}/></a>
                    </div>
                </Col>

                <Col xxs={4}  className='col-gift'>
                    <ScrollLink
                      className="gift-link"
                      to="header"
                      smooth={true}
                      duration={500}
                      offset={-70}
                    >
                    <img src={arrowUp} alt="Gif" width="100px" />
                    </ScrollLink>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;