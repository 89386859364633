import './about.css';
import { ReactComponent as IconWeb } from './icons/web.svg';
import { ReactComponent as IconBack } from './icons/back.svg';
import { ReactComponent as IconData } from './icons/data.svg';
import { ReactComponent as IconCloud } from './icons/cloud.svg';
import { ReactComponent as IconPoint } from './icons/point.svg';
import {
    Container, Row, Col, Button, Navbar
} from 'reactstrap';

const About = () => {
    return (
        <Container className='about-container pt-5' id='about'>
            <Row>
                <Col lg={5} className='col-title'>
                    <h1 className='col-title-h1'>A PROPOS DE MOI</h1>
                    <p>
                        <span><IconPoint />Passionnée</span>
                        <span><IconPoint />Déterminée</span>
                        <span><IconPoint />Curieuse</span>
                    </p>
                    <a href='../../file/MonCV.pdf' download>
                        <Button className='col-title-btn'>Télecharger CV</Button>
                    </a>
                    <div className='col-title-icon'>
                        <IconWeb width={40} height={50} />
                        <IconBack width={40} height={50} />
                        <IconData width={40} height={50} />
                        <IconCloud width={40} height={50} />
                    </div>
                </Col>

                <Col lg={7} className='pt-5 col-text'>
                    <p>
                        Développeuse fullstack, je crée des applications web modernes et performantes, de l'interface utilisateur au déploiement. Je gère également les données et optimise les flux d'information grâce à l'intégration de processus ETL. Je transforme vos idées en solutions fiables et sur-mesure. Curieuse et polyvalente, j'aime explorer les nouvelles technologies pour proposer des produits optimisés et adaptés aux besoins spécifiques de chaque projet.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default About;