import profile from './profile.png';
import bannerGif from './bannerGif.gif';
import buttonGif from './buttonGif.gif'
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import {
  Container, Row, Col, Button
} from 'reactstrap';



const Div = styled.div`
    width: 70%;
    margin:0 auto;

    .img1 img, .img2 img{
        transform: scaleX(-1);
    }

    .img2 img{
        display:none;
    }

    h1, h2{
        color: #fff;    
    }

    .banner-title{
      padding:10px 0 10px 0;
    }

    span{
        color: #A072FF;  
    }

    .banner-decor {
      border-bottom: solid 4px #A072FF;
      
    }
    .banner-decor2 {
       border-left: solid 4px #A072FF;
       border-right: solid 4px #A072FF;
       
     }

    .banner-point{
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background: #A072FF;
        margin:auto;
    }
    .banner-hr{
       
        border-left: solid 4px #A072FF;
        padding-bottom: 100px; 
        width:0px; 
        margin:auto;
    }

.bull span {
  width: 5vmin;
  height: 5vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bull span:nth-child(1) {
  color: #583C87;
  animation-duration: 11.8s;
  animation-delay: -4.8s;
  transform-origin: 20vw 5vh;
  box-shadow: 0vmin 20vmin 10vmin currentColor;
}

.bull span:nth-child(2) {
  color: #E45A84;
  animation-duration: 12s;
  animation-delay: -5s;
  transform-origin: 21.5vw 5vh;
  box-shadow: 22vmin 0vmin 10vmin currentColor;
}

.bull span:nth-child(3) {
  color: #FFACAC;
  animation-duration: 14.6s;
  animation-delay: -4.5s;
  transform-origin: 18.5vw 5vh;
  box-shadow: 19vmin 0 10vmin currentColor;
}
.bull span:nth-child(4) {
  color: #818049;
  animation-duration: 14.6s;
  animation-delay: -3.5s;
  transform-origin: 21.5vw 5vh;
  box-shadow: 20vmin 10vmin 10vmin currentColor;
}

.bull span:nth-child(5) {
  color: #EB3324;
  animation-duration: 12s;
  animation-delay: -6s;
  transform-origin: 21.5vw 5.5vh;
  box-shadow: 5vmin 10vmin 10vmin currentColor;
}
.bull span:nth-child(6) {
  color: #C0C0C0;
  animation-duration: 11.8s;
  animation-delay: -2.9s;
  transform-origin: 5vw 5vh;
  box-shadow: 0vmin 20vmin 10vmin currentColor;
}
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.banner-icon{
    display: flex;
    justify-content: space-between;
    padding: 60px 20px 0px 0px;
}

.banner-title{
  fontSize:'3em'; 
  padding:'20px 0px'
}

.banner-title2{
  fontSize:'2.5em'; 
}

.animate{
  text-align:center;
  padding-top:50px;
}
.scroll{
  text-decoration:none;
}
.portfolio-button {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: #7436ee;
  border: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  border-radius:20px;
  margin: auto;
}

.portfolio-button .slide-background {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background:#a072ff ;
  /*animation: slide 10s infinite;*/
  z-index: -1;
}

@keyframes slide {
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@media only screen (max-device-width: 767px) {
    display: block;
    position:relative;
    background-color:#191847;
    padding-top: 20px;
    top:0px;
    left:0px;
    margin:auto;

    .banner-decor {
      width:400px;
      margin: auto;
    }
    .banner-decor2, .banner-decor-text {
      width:392px;
      margin: auto;
    }
    .img1 img{
      display:none;
    }
    .img2 img{
      display:block;
      width: 400px;
      margin:auto;
    }
    .banner-div h1, .banner-div h2{
      text-align: center;
    }

    .banner-icon{
      padding: 20px 0px 0px 0px;
      gap: 20px;
    }
    
    
  }

`
export const Banner = () => {
  return (
    <section>
      <Container className='banner-container' style={{ margin: '0', padding: '0', margin: 'auto' }}>
        <Row g-0 className="w-100" >
          <Col lg={4} className='banner-first-col z-3'>
            <div className='d-flex banner-first-col-div'>
              <img src={profile} alt="Banner" className='img-fluid banner-first-col-img' />
            </div>
          </Col>

          <Col lg={8} className='banner-second-col z-2'>
            <Div>
              <h1 className='banner-title'>Salut! je suis <span>Christelle,</span></h1>
              <h2 className='banner-title2' >Je suis <span>Dévelopeuse Fullstack</span></h2>
              <div>
                <div class="bull">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                {/* <div className='banner-decor'>
                  <div className='banner-point'></div>
                  <div className='banner-hr'></div>
                </div>
                <div className='banner-decor2'>
                  <div className='banner-hr'></div>
                </div>

                <div className='banner-decor-text'>
                  <div className='banner-text'>frontend</div>
                  <div className='banner-text2'>backend</div>
                  <div className='banner-text2'>etl</div>
                </div> */}
                <div className='animate'>
                  <ScrollLink
                    className="scroll"
                    to="portefolio"
                    smooth={true}
                    duration={500}
                    offset={-70}
                  >
                    <button
                      className="portfolio-button d-block"
                      to="portefolio"
                      smooth={true}
                      duration={500}
                      offset={-70}
                    >
                      Mon Portefolio
                      <span className="slide-background"></span>
                      <img src={buttonGif} alt="Gif" width="30px" />
                    </button>
                  </ScrollLink>
                  <img src={bannerGif} alt="Gif" width="100px" height="90px" />
                </div>
              </div>
            </Div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}