import React from 'react';
import './header.css';
import NavBar from './Navbar';
import { Banner } from './Banner';
const Header = ({ scrollToSection }) => {
    return (
        <div id='header'>
          <NavBar scrollToSection={scrollToSection}/>
          <Banner/>
        </div>
      );
}

export default Header;