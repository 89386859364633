import React from 'react';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import About from './components/about/About';
import Header from './components/header/Header';
import Skills from './components/skills/Skills';
import Resume from './components/resume/Resume';
import Work from './components/work/Work';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div>
      <Header/>
      <About/>
      <Skills/>
      <Resume/>
      <Work/>
      <Footer/>
    </div>
  );
}

export default App;
