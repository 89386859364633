import './work.css';
import { useState } from "react";
import { ReactComponent as IconFinger } from './icons/finger.svg';
import { ReactComponent as IconSymfony } from '../skills/icons/symfony.svg';
import { ReactComponent as IconMailjet } from '../skills/icons/mailjet.svg';
import { ReactComponent as IconStripe } from '../skills/icons/stripe.svg';
import { ReactComponent as Iconbootstrap } from '../skills/icons/bootstrap.svg';
import { ReactComponent as IconTalend } from '../skills/icons/talend.svg';
import { ReactComponent as IconDocker } from '../skills/icons/docker.svg';
import { ReactComponent as IconApiPlatform } from '../skills/icons/apiPlatform.svg';
import { ReactComponent as IconNode } from '../skills/icons/node.svg';
import { ReactComponent as IconSocket } from '../skills/icons/socket.svg';
import { ReactComponent as IconReact } from '../skills/icons/react.svg';
import { ReactComponent as IconMaterial } from '../skills/icons/material.svg';
import { ReactComponent as IconRabbit } from '../skills/icons/rabbitmq.svg';
import { ReactComponent as IconFirebase } from '../skills/icons/firebase.svg';

import {
    Container, Row, Col, Button
} from 'reactstrap';

const Work = () => {
    const [hidden, setHidden] = useState(true);
    const [hidden1, setHidden1] = useState(true);
    const [hidden2, setHidden2] = useState(true);
    const [hidden3, setHidden3] = useState(true);
    return (
        <Container className='work-container pt-5' id='portefolio'>
            <h1 className='title'>MES PROJETS RECENTS</h1>
            <Row className='work-row'>
                <Col lg={6} className=''>
                    <div className='work-bloc'>
                        <h6 className='work-bloc-title'>SITE ECOMMERCE / TIRELIRE</h6>
                        <div className='' style={{ height: '220px' }}>
                            <div className='project-desc'>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Authentification</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Produits - Panier</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Commande - Paiement</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Emails</span></p>
                                <div className='desc-icon'>
                                    <IconSymfony height={50} width={40} />
                                    <IconStripe height={50} width={45} />
                                    <IconMailjet height={50} width={50} />
                                    <IconDocker height={50} width={40} />
                                    <Iconbootstrap height={50} width={40} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6} className=''>
                    <div className='work-bloc'>
                        <h6 className='work-bloc-title'>EXPORT DE DONNEES</h6>
                        <div className='' style={{ height: '220px' }}>
                            <div className='project-desc'>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Authentification</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Démande d'export</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>API</span></p>
                                <div className='desc-icon'>
                                    <IconSymfony height={50} width={40} />
                                    <IconTalend height={50} width={40} />
                                    <IconApiPlatform height={50} width={40} />
                                    <IconDocker height={50} width={40} />
                                    <Iconbootstrap height={50} width={40} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='work-row'>
                <Col lg={6} className=''>
                    <div className='work-bloc'>
                        <h6 className='work-bloc-title'>NOTIFICATION / WIDGET</h6>
                        <div className='' style={{ height: '220px' }}>
                            <div className='project-desc'>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Gestion de clients</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Envoie de notification</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Reception de notification</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Consommation de message</span></p>
                                <div className='desc-icon'>
                                    <IconNode height={50} width={40} />
                                    <IconSocket height={50} width={45} />
                                    <IconReact height={50} width={40} />
                                    <IconMaterial height={50} width={40} />
                                    <IconRabbit height={50} width={35} />
                                    <IconDocker height={50} width={40} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6} className=''>
                    <div className='work-bloc'>
                        <h6 className='work-bloc-title'>PORTEFOLIO</h6>
                        <div className='' style={{ height: '220px' }}>
                            <div className='project-desc'>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Portefolio</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Blog</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>CV</span></p>
                                <p className='desc-fonct'><IconFinger height={25} /> <span>Contact</span></p>
                                <div className='desc-icon'>
                                    <IconReact height={50} width={40} />
                                    <Iconbootstrap height={50} width={45} />
                                    <IconFirebase height={50} width={50} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Work;