import './resume.css'
import {
    Container, Row, Col, Button
} from 'reactstrap';

const Resume = () => {
    return (
        <Container className='resume-container' id='resume'>
            <h1 style={{textAlign:'center', color:'#7436ee'}}>PARCOURS</h1>
            <Row style={{paddingTop:'40px'}}>
                <Col lg={6} md={6} className='col-edu'>
                    <h2 className='first-title'>Education</h2>
                    <div className='main-div'>
                        <div>
                            <div className='div-point'>
                                <div><span className='point'></span></div>
                                <h4 className='point-title'>2022-2024</h4>
                            </div>
                            <div className='content'>
                                <h6 style={{paddingTop:'20px'}}>Mastère</h6>
                                <div>
                                    <h4>HETIC</h4>
                                    <p>Mastère CTO & Tech Lead, titre RNCP</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='div-point2'>
                                <div><span className='point'></span></div>
                                <h4 className='point-title'>2020-2021</h4>
                            </div>
                            <div className='content'>
                                <h6>Licence</h6>
                                <div>
                                    <h4>CERCO</h4>
                                    <p>Licence en Genie Logiciel.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='div-point2'>
                                <div><span className='point'></span></div>
                                <h4 className='point-title'>2020-2021</h4>
                            </div>
                            <div className='content'>
                                <h6>BTS</h6>
                                <div>
                                    <h4>INSFP Relizane</h4>
                                    <p>BTS en maintenance des systèmes informatiques.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6} md={6} className='col-exp'>
                    <h2 className='first-title'>Expérience</h2>
                    <div className='main-div'>
                        <div>
                            <div className='div-point'>
                                <div><span className='point'></span></div>
                                <h4 className='point-title'>2022-2024</h4>
                            </div>
                            <div className='content'>
                                <h6 style={{paddingTop:'20px'}}>AnyCommerce By ChapsVision</h6>
                                <div>
                                    <h4>Alternance</h4>
                                    <p>Développeuse backend / Développeuse Talend.</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='div-point2'>
                                <div><span className='point'></span></div>
                                <h4 className='point-title'>08/2022 - 11/2022</h4>
                            </div>
                            <div className='content'>
                                <h6>AnyCommerce By ChapsVision</h6>
                                <div>
                                    <h4>Stage</h4>
                                    <p>Développeuse backend </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='div-point2'>
                                <div><span className='point'></span></div>
                                <h4 className='point-title'>2020-2021</h4>
                            </div>
                            <div className='content'>
                                <h6>OPEN CI</h6>
                                <div>
                                    <h4>Alternance</h4>
                                    <p>Développeuse frontend</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Resume;