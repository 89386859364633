import './skills.css';
import { ReactComponent as IconHtml } from './icons/html.svg';
import { ReactComponent as IconCss } from './icons/css.svg';
import { ReactComponent as IconJS } from './icons/js.svg';
import { ReactComponent as IconReact } from './icons/react.svg';
import { ReactComponent as IconPhp } from './icons/php.svg';
import { ReactComponent as IconSymfony } from './icons/symfony.svg';
import { ReactComponent as Iconbootstrap } from './icons/bootstrap.svg';
import { ReactComponent as IconTalend } from './icons/talend.svg';
import { ReactComponent as IconDocker } from './icons/docker.svg';
import { ReactComponent as IconGit } from './icons/git.svg';
import { ReactComponent as IconNode } from './icons/node.svg';
import { ReactComponent as IconSocket } from './icons/socket.svg';
import { ReactComponent as IconMaterial } from './icons/material.svg';
import { ReactComponent as IconGithub } from './icons/github.svg';
import { ReactComponent as IconGitlab } from './icons/gitlab.svg';
import { ReactComponent as IconSQL } from './icons/sql.svg';
import { ReactComponent as IconMysql } from './icons/mysql.svg';
import { ReactComponent as IconPostgres } from './icons/postgres.svg';
import { ReactComponent as IconCICD } from './icons/cicd.svg';
import { ReactComponent as IconLinux } from './icons/linux.svg';
import { ReactComponent as IconMailjet } from './icons/mailjet.svg';
import { ReactComponent as IconStripe } from './icons/stripe.svg';
import { ReactComponent as IconScaleway} from './icons/scaleway.svg';
import { ReactComponent as IconApiPlatform} from '../skills/icons/apiPlatform.svg';

import {
    Container, Row, Col
} from 'reactstrap';

const Skills = () =>{
    return(
        <Container className='skills-container' style={{padding:'100px'}}>
            <h1 style={{textAlign:'center', color:'#7436ee'}}>COMPETENCES - STACKS UTILISES</h1>
            <Row className=''>
                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconHtml width={25} style={{marginRight:'10px'}}/>
                            HTML
                        </p>
                        <p>
                            <IconCss width={25} style={{marginRight:'10px'}}/>
                            CSS
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <Iconbootstrap width={25} style={{marginRight:'10px'}}/>
                            BOOTSTRAP
                        </p>
                        <p>
                            <IconMaterial width={25} style={{marginRight:'10px'}}/>
                            MATERIAL UI
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconJS width={25} style={{marginRight:'10px'}}/>
                            JAVASCRIPT
                        </p>
                        <p>
                            <IconReact width={25} style={{marginRight:'10px'}}/>
                            REACT JS
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconPhp width={25} style={{marginRight:'10px'}}/>
                            PHP
                        </p>
                        <p>
                            <IconNode width={25} style={{marginRight:'10px'}}/>
                            NODE JS
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconSymfony width={25} style={{marginRight:'10px'}}/>
                            SYMFONY
                        </p>
                        <p>
                            <IconApiPlatform width={25} style={{marginRight:'10px'}}/>
                            API PLATFORM
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconTalend width={25} style={{marginRight:'10px'}}/>
                            TALEND
                        </p>
                        <p>
                            <IconSQL width={25} style={{marginRight:'10px'}}/>
                            SQL
                        </p>
                    </div>
                </Col>
            </Row>

            <Row className=''>
                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconMysql width={25} style={{marginRight:'10px'}}/>
                            MYSQL
                        </p>
                        <p>
                            <IconPostgres width={25} style={{marginRight:'10px'}}/>
                            POSTGRESQL
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconDocker width={25} style={{marginRight:'10px'}}/>
                            DOCKER
                        </p>
                        <p>
                            <IconCICD width={25} style={{marginRight:'10px'}}/>
                            CI/CD
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconGit width={25} style={{marginRight:'10px'}}/>
                            GIT
                        </p>
                        <p>
                            <IconLinux width={25} style={{marginRight:'10px'}}/>
                            LINUX
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconGithub width={30} style={{marginRight:'10px'}}/>
                            GITHUB
                        </p>
                        <p>
                            <IconGitlab width={25} style={{marginRight:'10px'}}/>
                            GITLAB
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                    <p>
                            <IconScaleway width={25} style={{marginRight:'10px'}}/>
                            SCALEWAY
                        </p>
                        <p>
                            <IconSocket width={25} style={{marginRight:'10px'}}/>
                            SOCKET IO
                        </p>
                    </div>
                </Col>

                <Col lg={2} md={4} xs={6} >
                    <div className='col-skills'>
                        <p>
                            <IconMailjet width={25} style={{marginRight:'10px'}}/>
                            MAILJET
                        </p>
                        <p>
                            <IconStripe width={25} style={{marginRight:'10px'}}/>
                            STRIPE
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Skills;